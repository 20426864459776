const TermsOfUse = '/termsOfUse';

export const StaticRoutes = {
	TermsOfUse
};

export const routes = [
	{
		url: TermsOfUse,
		name: 'TermsOfUse',
		component: 'TermsOfUse'
	}
];

const combined = {StaticRoutes, routes};

export default combined;
