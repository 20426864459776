import React, { useContext, useEffect, useState, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import { transform } from 'ol/proj';
import OLTileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import Papa from 'papaparse';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Overlay from 'ol/Overlay';

import MapContext from "../Map/MapContext";

const InfoTemperatureControl = ({ i18n, action, cropCoordinates = null }) => {
  const { map } = useContext(MapContext);

  const containerRef = useRef();
  const contentRef = useRef();
  const closerRef = useRef();

  let overlay = null;

  useEffect(() => {
    if (!map) return;

    overlay = new Overlay({
      element: containerRef.current,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });

    closerRef.current.onclick = function () {
      overlay.setPosition(undefined);
      closerRef.current.blur();
      return false;
    };

    map.addOverlay(overlay);
    map.on('singleclick', showInfo);

    return () =>  {
      map.un('singleclick', showInfo);
      map.removeOverlay(overlay);
    }

  });


  const showInfo = (evt) => {

    let inside = true;
    if (cropCoordinates) {
      const f = new Feature(new MultiPolygon(cropCoordinates));
      inside = (f.getGeometry().intersectsCoordinate(evt.coordinate));
    }

    if (!inside) return;


    const layers = map.getLayers().getArray().filter(e => !e.get('baseLayer'));

    const requests = [];
    const viewResolution = /** @type {number} */ (map.getView().getResolution());
    layers.forEach(layer => {
      const url = layer.getSource().getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        'EPSG:3857',
        { 'INFO_FORMAT': 'application/json' }
      );
      if (url) {
        requests.push(fetch(url).then(y => y.json()));
      }
    });

    Promise.all(requests).then(results => {
      if (results[0].features.length > 0 && results[1].features.length > 0) {
        const coordinates = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
        contentRef.current.innerHTML = '<h5>' + i18n['temperatureChange'] + '</h5><p>' + i18n[action] + ': <b>' + results[0].features[0].properties['GRAY_INDEX'].toFixed(1) + '°C</b><br/>' + i18n['beforeAction'] + ':  <b>' + results[1].features[0].properties['GRAY_INDEX'].toFixed(1) + '°C</b></p><code>' + i18n['coordinates'] + ': ' + coordinates[0].toFixed(3) + ',' + coordinates[1].toFixed(3) + ' </code>';
        overlay.setPosition(evt.coordinate);
      }
    });


  }


  return <div ref={containerRef} className="ol-popup">
    <a href="#" ref={closerRef} className="ol-popup-closer"></a>
    <div ref={contentRef}></div>
  </div>;
};

export default InfoTemperatureControl;