import React, { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Container, Row, Col, Label, Input, Form, FormGroup } from 'reactstrap';
import OLTileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import { transform } from 'ol/proj';
import { requestData, getData, getInfo } from 'core/ducks/list';
import { bindActionCreators } from 'redux';
import ChartLine from "../ChartLine";
import MapContext from "../Map/MapContext";

const InfoControl = ({ activeLayer, layers, i18n, dispatch }) => {
  const { map } = useContext(MapContext);
  const [modal, setModal] = useState(false);
  const [infoResults, setInfoResults] = useState(null);
  const [period, setPeriod] = useState('present');
  const [action, setAction] = useState('all_params');
  const [chartData, setChartData] = useState(null);
  const { messages } = i18n || { messages: {} };

  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {

    }
  }

  useEffect(() => {
    if (!map) return;

    map.on('singleclick', showInfo);

    return () => map.un('singleclick', showInfo);

  }, [map]);

  const showInfo = (evt) => {
    setInfoResults(null);
    const requests = [];
    const viewResolution = /** @type {number} */ (map.getView().getResolution());
    const tileLayer = new OLTileLayer({
      name: activeLayer,
      source: new TileWMS({
        url: 'https://app.adapt2climate.gr/geoserver/gisdata/wms',
        params: { 'LAYERS': activeLayer, 'TILED': true },
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      })
    });

    const url = tileLayer.getSource().getFeatureInfoUrl(
      evt.coordinate,
      viewResolution,
      'EPSG:3857',
      { 'INFO_FORMAT': 'application/json' }
    );
    if (url) {
      requests.push(fetch(url).then(y => y.json()));
    }

    Promise.all(requests).then(results => {
      results.forEach((r, i) => {
        if (r.features.length > 0) {
          let mean = null;
          const categories = [];
          const data = [];
          for (const prop in r.features[0].properties) {
            if (prop.includes('year')) {
              if (prop.split('_year_')[1] === 'mean') {
                mean = r.features[0].properties[prop]
              } else {
                categories.push(prop.split('_year_')[1]);
                data.push(r.features[0].properties[prop])
              }
            }
          }

          let extra = false;
          if (r.features[0].properties.akz_code < 3) {
            extra = true
          }
          if (r.features[0].properties.akz_code <= 3 && r.features[0].id.includes('athens')) {
            extra = true
          }

          let domain = '';
          if (r.features[0].id.includes('larissa')) {
            domain = 'larissa'
          } else if (r.features[0].id.includes('athens')) {
            domain = 'athens'
          } else if (r.features[0].id.includes('rethymno')) {
            domain = 'rethymno'
          }

          setInfoResults({ title: r.features[0].properties.title, domain: domain, akz: r.features[0].properties.akz_code, zone: r.features[0].properties.message, coordinates: transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326'), extra: extra });
          toggle();
        }
      });
    });

  }

  const handleSelectPeriod = (e) => {
    setPeriod(e.target.value);
  }

  const handleSelectAction = (e) => {
    setAction(e.target.value);
  }

  useEffect(() => {
    if (infoResults) {
      setChartData(null);
      fetch(`api/history/domain/${infoResults.domain}/period/${period}/akz/akz${infoResults.akz}`)
        .then(response => response.json())
        .then(data => {
          const array = Object.entries(data).map(([key, value]) => ({ key, value }));
          setChartData({
            categories: array.map(e => e.value['time'].slice(11, -3)),
            data: array.map(e => e.value[action]),
            noActionData: array.map(e => e.value['no_params'])
          })
        })
        .catch(error => console.error(error));
    }
  }, [infoResults, period, action]);



  return <div>
    <Modal isOpen={modal} toggle={toggle} size={'lg'}>
      {
        infoResults && <React.Fragment><ModalHeader toggle={toggle}>{`${messages['zone']}: ${infoResults.title}`}</ModalHeader>
          <ModalBody>
            <Container>
              <div>
                <h5 style={{ textAlign: 'center' }}>{`${infoResults.zone}`} </h5>
                {
                  infoResults.extra && <Form>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="period_selector" sm={2}>{messages['period']}</Label>
                          <Input id="period_selector" type="select" value={period} onChange={handleSelectPeriod}>
                            <option value={'present'}>{messages['present']}</option>
                            <option value={'future'}>{messages['futur']}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="action_selector" sm={2}>{messages['action']}</Label>
                          <Input id="action_selector" type="select" value={action} onChange={handleSelectAction}>
                            <option value={'all_params'}>{messages['all_actions']}</option>
                            <option value={'vegetation'}>{messages['vegetation']}</option>
                            <option value={'roofs'}>{messages['roofs']}</option>
                            <option value={'roads'}>{messages['roads']}</option>
                            <option value={'pavements'} >{messages['pavements']}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {
                          chartData && <ChartLine showTitle={true} title={messages['TypicalDay']} yaxisTitle={`${messages['temperature']}`} units={`°C`} xaxisTitle = {messages['TimeOfDay']} height={350} categories={chartData.categories} series={[
                            {
                              name: messages[action],
                              data: chartData.data
                            },
                            {
                              name: messages['no_action'],
                              data: chartData.noActionData
                            }
                          ]} />
                        }
                      </Col>
                    </Row>
                  </Form>
                }
                <div style={{ textAlign: 'right' }}>{`${messages['coordinates']}: ${infoResults.coordinates[0].toFixed(3)}, ${infoResults.coordinates[1].toFixed(3)}`}</div>
              </div>
            </Container>
          </ModalBody>
        </React.Fragment>
      }
    </Modal>
  </div>;
};


const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(InfoControl);