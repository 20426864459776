import * as roles from 'core/model/roles';

const Stations = '/stations';
const Climate = '/climate';
const Zones = '/zones';
const VulnerabilityIndex = '/vulnerability';

const AdminStations = '/admin/stations';
const AdminClimate = '/admin/climate';
const AdminZones = '/admin/zones';
const AdminVulnerabilityIndex = '/admin/vulnerability';

export const StaticRoutes = {
	Stations,
	Climate,
	Zones,
	VulnerabilityIndex,
	AdminStations,
	AdminClimate,
	AdminZones,
	AdminVulnerabilityIndex
};

export const routes = [
	{
		url: Stations,
		name: 'Stations',
		component: 'Stations',
		role: roles.GUEST
	},
	{
		url: Climate,
		name: 'Climate',
		component: 'Climate',
		role: roles.GUEST
	},
	{
		url: Zones,
		name: 'Zones',
		component: 'Zones',
		role: roles.GUEST
	},
	{
		url: VulnerabilityIndex,
		name: 'VulnerabilityIndex',
		component: 'VulnerabilityIndex',
		role: roles.GUEST
	},
	{
		url: AdminStations,
		name: 'Stations',
		component: 'Stations',
		role: [roles.ADMIN, roles.AUTHORIZED]
	},
	{
		url: AdminClimate,
		name: 'Climate',
		component: 'Climate',
		role: [roles.ADMIN, roles.AUTHORIZED]
	},
	{
		url: AdminZones,
		name: 'Zones',
		component: 'Zones',
		role: [roles.ADMIN, roles.AUTHORIZED]
	},
	{
		url: AdminVulnerabilityIndex,
		name: 'VulnerabilityIndex',
		component: 'VulnerabilityIndex',
		role: [roles.ADMIN, roles.AUTHORIZED]
	}
];

const combined = {StaticRoutes, routes};

export default combined;
