import * as roles from '../model/roles';

const Admin = '/admin';
// const PassRecovery = '/password_recovery';

export const Pages = {
	Admin
};

/**
 * Static routes
 */

// Admin
const AdminDashboard = '/admin/dashboard';
const Dashboard = '/dashboard';
const Profile = '/profile';
const Root = '/';
const Settings = '/admin/settings';
const GeneralSettings = Settings + '/general';
const FrontPageSettings = Settings + '/frontpage';

export const StaticRoutes = {
	AdminDashboard,
	Dashboard,
	Profile,
	Root,
	Settings,
	GeneralSettings,
	FrontPageSettings,
};

/**
 * Dynamic routes
 */

export const DynamicRoutes = {};

/**
 * Routes for error pages
 */

const Forbidden = '/error/403';
const NotFound = '/error/404';

export const ErrorPages = {
	Forbidden,
	NotFound,
};

export const routes = [
	{
		url: Root,
		name: 'home',
		component: 'Front',
		exact: true,
		role: roles.GUEST
	},
	{
		url: Root,
		name: 'home',
		to: AdminDashboard,
		exact: true,
		role: roles.AUTHORIZED
	},
	{
		url: AdminDashboard,
		name: 'dashboard',
		component: 'Dashboard',
		role: roles.AUTHORIZED
	},
	{
		url: Settings,
		name: 'settings',
		to: GeneralSettings,
		exact: true,
	},
	{
		url: GeneralSettings,
		name: 'general',
		component: 'GeneralSettings',
		role: roles.ADMIN,
	},
	{
		url: FrontPageSettings,
		name: 'front',
		component: 'FrontPageSettings',
		role: roles.ADMIN,
	},
	{
	 	url: Dashboard,
	 	name: 'dashboard',
	 	component: 'Dashboard'
	},
	{
		url: Profile,
		name: 'profile',
		component: 'Profile',
		role: roles.AUTHORIZED
	},
];

export default routes;
