import ImageWMS from 'ol/source/ImageWMS';

function imageWms({ url, params }) {
	return new ImageWMS({
		url,
    params
	});
}

export default imageWms;
