import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from "react-markdown";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

class TermsOfUse extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>{this.props.i18n['termsOfUse']}</BreadcrumbItem>
                </Breadcrumb>
                <ReactMarkdown source={this.props.i18n['termsOfUseText']} escapeHtml={false} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    i18n: state.i18n.messages
});

TermsOfUse = connect(mapStateToProps)(TermsOfUse);

export default TermsOfUse;