import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Map from "../../components/Map";
import { Layers, LayerGroup, TileLayer, ImageLayer } from "../../components/Layers";
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from 'reactstrap';
import { bing, imageWMS } from "../../components/Source";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  InfoRasterControl,
  LegendControl,
  OpacityControl,
  BookmarkControl,
  BasemapControl
} from "../../components/Controls";
import "../../css/style.css";

import config from "../config.json";

const Climate = (props) => {
  const [center, setCenter] = useState(config.places[0].center);
  const [zoom, setZoom] = useState(config.places[0].zoom);

  const [category, setCategory] = useState(config.parameter[0]);
  const [index, setIndex] = useState(config.parameter[0].index[0]);
  const [period, setPeriod] = useState('history');
  const [year, setYear] = useState(config.parameter[0].period[0].range[0]);
  const [scenario, setScenario] = useState(config.parameter[0].period[0].scenario[0]);
  const [model, setModel] = useState('histo');
  const [activeLayers, setActivelayers] = useState([]);

  const handleSelectCategory = (e) => {
    const value = e.target.value;
    setCategory(config.parameter.filter(p => p.category === value)[0]);
    setIndex(config.parameter.filter(p => p.category === value)[0].index[0]);
    setYear(config.parameter[0].period[0].range[0]);
    setScenario(config.parameter[0].period[0].scenario[0]);
    setModel('histo');
  }

  const handleIndex = (e) => {
    const value = e.target.value;
    setIndex(value)
  }

  const handlePeriod = (e) => {
    const value = e.target.value;
    setPeriod(value);
    setYear(category.period.find(p => p.name === value).range[0]);
    setScenario(category.period.find(p => p.name === value).scenario[0]);
  }

  const handleYear = (e) => {
    const value = e.target.value;
    setYear(value);
  }

  const handleScenario = (e) => {
    const value = e.target.value;
    setScenario(value);
  }

  useEffect(() => {
    switch (scenario) {
      case 'CORDEX':
        setModel('histo');
        break;
      case 'RCP4.5':
        setModel('rcp45');
        break;
      case 'RCP8.5':
        setModel('rcp85');
        break;
      default:
        setModel('histo');
    }

    let ly = 'CORDEX'
    if (scenario === 'ERA5') {
      ly = 'ERA5'
    }

    const layer = config.model.layers.find(e => e.includes(ly) && e.includes(index));

    layer ? setActivelayers([layer]) : setActivelayers([]);

  }, [category, index, period, year, scenario])

  return (


    <Card>
      <CardBody>
        <CardTitle tag="h5">{props.i18n.messages['climate']}</CardTitle>
        <CardBody>
          {props.i18n.messages['climateText']}
        </CardBody>
        <Row>
          <Col>
            <Label for="category_selector">{props.i18n.messages['ClimaticParameterCategory']}</Label>
            <Input id="category_selector" type="select" value={category[0]} onChange={handleSelectCategory}>
              {
                config.parameter.map(e => <option value={e.category} key={`category_option_${e.category}`}>{props.i18n.messages[e.category]}</option>)
              }
            </Input>
          </Col>
          <Col>
            <Label for="index_selector">{props.i18n.messages['index']}</Label>
            <Input id="index_selector" type="select" value={index} onChange={handleIndex}>
              {
                category.index && category.index.map(e => <option value={e} key={`index_option_${e}`}>{props.i18n.messages[e]}</option>)
              }
            </Input>
          </Col>
          <Col>
            <Label for="period_selector">{props.i18n.messages['ReportingPeriod']}</Label>
            <Input id="period_selector" type="select" value={period} onChange={handlePeriod}>
              <option value={'history'} key={`period_option_history`}>{props.i18n.messages['history']}</option>
              <option value={'near'} key={`period_option_near`}>{props.i18n.messages['near']}</option>
              <option value={'future'} key={`period_option_future`}>{props.i18n.messages['future']}</option>
            </Input>
          </Col>
          <Col>
            <Label for="year_selector">{props.i18n.messages['year']}</Label>
            <Input id="year_selector" type="select" value={year} onChange={handleYear}>
              {
                category.period && Array.from({ length: category.period.find(p => p.name === period).range[1] - category.period.find(p => p.name === period).range[0] + 1 }, (_, i) => category.period.find(p => p.name === period).range[0] + i).map(e => <option value={e} key={`year_option_${e}`}>{e}</option>)
              }
            </Input>
          </Col>
          <Col>
            <Label for="scenario_selector">{scenario === 'CORDEX' || scenario === 'ERA5' ? props.i18n.messages['model'] : props.i18n.messages['scenario']}</Label>
            <Input id="scenario_selector" type="select" value={scenario} onChange={handleScenario}>
              {
                category.period && category.period.find(p => p.name === period).scenario.map(e => <option value={e} key={`scenario_option_${e}`}>{props.i18n.messages[e]}</option>)
              }
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Map center={center} zoom={zoom} loggedin={props.loggedin}>
              <Layers key={`map-key-${Math.random()}`}>
                <TileLayer title={props.i18n.messages['Road']} name={'Road'} baseLayer={true} visible={false} source={bing('Road', props.i18n.locale)} zIndex={0} logo={"/img/road.png"} />
                <TileLayer title={props.i18n.messages['Aerial']} name={'Aerial'} baseLayer={true} visible={false} source={bing('Aerial', props.i18n.locale)} zIndex={0} logo={"/img/aerial.png"} />
                <TileLayer title={props.i18n.messages['Hybrid']} name={'Hybrid'} baseLayer={true} visible={true} source={bing('AerialWithLabelsOnDemand', props.i18n.locale)} zIndex={0} logo={"/img/hybrid.png"} />
                {
                  activeLayers.map(e => <ImageLayer
                    cropCoordinates={config.crop}
                    key={`active-station-layer-${e}`}
                    name={props.i18n.messages[e]}
                    baseLayer={false}
                    visible={true}
                    extent={[2224961, 4112383, 3213139, 5161710]}
                    opacity={0.8}
                    source={imageWMS({
                      url: config.model.wms,
                      params: { 'LAYERS': e, 'TILED': true, 'TIME': `${year}-01-01T00:00:00.000Z`, 'DIM_MODEL': model }
                    })} />)
                }
              </Layers>
              <Controls>
                <InitialZoomControl center={fromLonLat(config.center)} zoom={config.zoom} />
                <ZoomControl />
                <ScaleLineControl />
                <InfoRasterControl key={`map-info-key-${Math.random()}`} cropCoordinates={config.crop} index={index} year={year} model={model} period={category.period.find(p => p.name === period).range} activeLayer={activeLayers[0]} />
                <LegendControl wms={config.model.wms} activeLayer={activeLayers[0]} style={{ top: 'unset', bottom: '0.5em' }} />
                <BookmarkControl places={config.places} messages={props.i18n.messages} />
                <OpacityControl initOpacity={0.8} />
                <BasemapControl />
              </Controls>
            </Map>
          </Col>
        </Row>
      </CardBody>
    </Card>

  );
};

const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Climate);