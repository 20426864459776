import React, { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import OLTileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import ChartLine from "../ChartLine";
import MapContext from "../Map/MapContext";

const InfoControl = ({ activeLayer, layers, i18n }) => {
  const { map } = useContext(MapContext);
  const [modal, setModal] = useState(false);
  const [infoResults, setInfoResults] = useState(null);
  const { messages } = i18n || { messages: {} };

  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {

    }
  }

  useEffect(() => {
    if (!map) return;

    map.on('singleclick', showInfo);

    return () => map.un('singleclick', showInfo);

  }, [map]);

  const showInfo = (evt) => {
    setInfoResults(null);
    const requests = [];
    const viewResolution = /** @type {number} */ (map.getView().getResolution());
    const tileLayer = new OLTileLayer({
      name: activeLayer,
      source: new TileWMS({
        url: 'https://app.adapt2climate.gr/geoserver/gisdata/wms',
        params: { 'LAYERS': activeLayer, 'TILED': true },
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      })
    });

    const url = tileLayer.getSource().getFeatureInfoUrl(
      evt.coordinate,
      viewResolution,
      'EPSG:3857',
      { 'INFO_FORMAT': 'application/json' }
    );
    if (url) {
      requests.push(fetch(url).then(y => y.json()));
    }

    Promise.all(requests).then(results => {
      results.forEach((r, i) => {
        if (r.features.length > 0) {
          let mean = null;
          const categories = [];
          const data = [];
          const ln = Object.keys(r.features[0].properties).length;

          if (ln > 7) {
            for (const prop in r.features[0].properties) {
              if (prop.includes('year')) {
                if (prop.split('_year_')[1] === 'mean') {
                  mean = r.features[0].properties[prop]
                } else {
                  categories.push(prop.split('_year_')[1]);
                  data.push(r.features[0].properties[prop])
                }
              }
            }
            setInfoResults({ station: r.features[0].properties.station, mean: mean, categories: categories, data: data });
            toggle();
          }else{
            for (const prop in r.features[0].properties) {
              if(prop.includes('value')){
                mean = r.features[0].properties[prop]
              }
            }
            setInfoResults({ station: r.features[0].properties.station, mean: mean });
            toggle();
          }
        }
      });
    });

  }

  return <div>
    <Modal isOpen={modal} toggle={toggle} size={'lg'}>
      <ModalHeader toggle={toggle}>{messages['station']}: {infoResults ? messages[infoResults.station] : null}</ModalHeader>
      <ModalBody>
        <Container>
          {
            infoResults && <div>
              <h6>{messages[activeLayer]}</h6>
              <div style={{ textAlign: 'center' }}>
                {`${messages['mean']}: ${infoResults.mean.toFixed(1)}`}
              </div>
              {
                infoResults.categories && <ChartLine categories={infoResults.categories} series={[
                {
                  name: messages['value'],
                  data: infoResults.data
                }
              ]} />
            }
            </div>
          }
        </Container>
      </ModalBody>
    </Modal>
  </div>;
};


const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(InfoControl);