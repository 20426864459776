import React, { useState } from "react";
import { connect } from 'react-redux';
import Map from "../../components/Map";
import { Layers, TileLayer } from "../../components/Layers";
import { Card, CardBody, CardTitle, Row, Col, Label, Input, FormGroup  } from 'reactstrap';
import { wms, bing } from "../../components/Source";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  BookmarkControl,
  LegendControl,
  OpacityControl,
  BasemapControl,
  SwipeControl,
  InfoTemperatureControl
} from "../../components/Controls";
import "../../css/style.css";

import config from "../config.json";

const Temperature = (props) => {
  const [center, setCenter] = useState(config.zones.center);
  const [zoom, setZoom] = useState(config.zones.zoom);
    const [activeLayers, setActivelayers] = useState(['cli_ras_cordexfilesCORDEX_T2avgmin_year_w']);
  const [period, setPeriod] = useState('present');
  const [action, setAction] = useState('all_params');
  const [swipeLayer, setSwipeLayer] = useState('cli_ras_CORDEX_1991_2020_T2prcmax'); 

  const handleSelectPeriod = (e) => {
    setPeriod(e.target.value);
  }

  const handleSelectAction = (e) => {
    setAction(e.target.value);
  }

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">{props.i18n.messages['temperatureChange']}</CardTitle>
        <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="period_selector" sm={2}>{props.i18n.messages['period']}</Label>
                <Input id="period_selector" type="select" value={period} onChange={handleSelectPeriod}>
                  <option value={'present'}>{props.i18n.messages['present']}</option>
                  <option value={'future'}>{props.i18n.messages['futur']}</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="action_selector" sm={2}>{props.i18n.messages['action']}</Label>
                <Input id="action_selector" type="select" value={action} onChange={handleSelectAction}>
                  <option value={'all_params'}>{props.i18n.messages['all_actions']}</option>
                  <option value={'vegetation'}>{props.i18n.messages['vegetation']}</option>
                  <option value={'roofs'}>{props.i18n.messages['roofs']}</option>
                  <option value={'roads'}>{props.i18n.messages['roads']}</option>
                  <option value={'pavements'} >{props.i18n.messages['pavements']}</option>
                </Input>
              </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col>
            <Map center={fromLonLat(center)} zoom={zoom} loggedin={props.loggedin}>
              <Layers key={`map-key-${Math.random()}`}>
                <TileLayer title={props.i18n.messages['Road']} name={'Road'} baseLayer={true} visible={false} source={bing('Road', props.i18n.locale)} zIndex={0} logo={"/img/road.png"} />
                <TileLayer title={props.i18n.messages['Aerial']} name={'Aerial'} baseLayer={true} visible={true} source={bing('Aerial', props.i18n.locale)} zIndex={0} logo={"/img/aerial.png"} />
                <TileLayer title={props.i18n.messages['Hybrid']} name={'Hybrid'} baseLayer={true} visible={false} source={bing('AerialWithLabelsOnDemand', props.i18n.locale)} zIndex={0} logo={"/img/hybrid.png"} />
                {
                  activeLayers.map(e => <TileLayer
                    key={`active-station-layer-${e}`}
                    name={props.i18n.messages[e]}
                    baseLayer={false}
                    visible={true}
                    extent={[2224961, 4112383, 3213139, 5161710]}
                    opacity={0.8}
                    source={wms({
                      url: config.zones.wms,
                      params: { 'LAYERS': e, 'TILED': true }
                    })} />)
                }
              </Layers>
              <Controls>
                <SwipeControl key={`swipe-key-${Math.random()}`} wms={config.model.wms} layer={swipeLayer} titleLeft={props.i18n.messages[action]} titleRight={props.i18n.messages['beforeAction']} opacity={0.8} />
                <InitialZoomControl center={fromLonLat(center)} zoom={zoom} />
                <ZoomControl />
                <ScaleLineControl />
                <BookmarkControl places={config.zones.places} messages={props.i18n.messages} />
                <LegendControl key={`map-legend-key-${Math.random()}`} wms={config.model.wms} activeLayer={swipeLayer} sld={props.i18n.locale === 'en' ? '' : ''} style={{ top: 'unset', bottom: '0.5em' }} />
                <OpacityControl initOpacity={0.8}/>
                <BasemapControl />
                <InfoTemperatureControl action={action} i18n={props.i18n.messages} />
              </Controls>
            </Map>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Temperature);