import React, { useContext, useEffect, useState } from "react";
import { Input } from 'reactstrap';
import OLTileLayer from "ol/layer/Tile";
import OLImageLayer from "ol/layer/Image";
import MapContext from "../Map/MapContext";


const OpacityControl = ({initOpacity = 1}) => {
  const { map } = useContext(MapContext);
  const [opacity, setOpacity] = useState(initOpacity * 100)

  useEffect(() => {
    if (!map) return;

  }, [map]);

  const changeOpacity = (e) => {
    setOpacity(e.target.value);
    map.getLayers().forEach(element => {
      if (!element.get('baseLayer') && (element instanceof OLTileLayer || element instanceof OLImageLayer)) {
        try {
          element.setOpacity(e.target.value / 100)
        } catch (err) { }
      }
    });
  }

  return <div className="ol-control" style={{ bottom: '0.5em', zIndex: 100, left: '20%', width: '50%' }}>
    <div style={{ padding: '5px', borderRadius: '4px' }}>
      <Input
        id="range"
        name="range"
        type="range"
        min={0}
        max={100}
        value={opacity}
        onChange={changeOpacity}
      />
    </div>
  </div>;
};

export default OpacityControl;