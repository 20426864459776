import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Card,
    CardBody,
    Button,
    Form,
} from 'reactstrap';

import {clearTimeout, logout} from 'core/ducks/profile';
import T from 'modules/i18n';

class LogoutForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearTimeout = this.clearTimeout.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.clearTimeout();
    }

    clearTimeout() {
        this.props.dispatch(clearTimeout());
        this.props.dispatch(logout());
    }

    render() {

        // const {messages} = this.props.i18n || {messages: {}};

        const form = (
            <Form onSubmit={this.handleSubmit}>

                <Button
                    color="primary"
                    className="px-4"
                    type="Submit"
                >
                    <T>logout</T>
                </Button>
            </Form>
        );

        return (
            <Card className="py-5" style={{width: 44 + '%'}}>
                <CardBody className="text-center">
                    <h2><T>already login</T></h2>
                    <p className="text-muted"><T>would you like to log out</T></p>
                    {form}
                </CardBody>
            </Card>
        );
    }

}

const mapStateToProps = (state) => ({
    multilingual: state.ui.multilingual,
    i18n: state.i18n
});

LogoutForm = connect(mapStateToProps)(LogoutForm);

export default LogoutForm;
