import React, { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Table, Row, Col, Container, Card, CardBody, CardTitle } from 'reactstrap';
import Select from "ol/interaction/Select";
import MapContext from "../Map/MapContext";

const FeatureInfoControl = (props) => {
	const { map } = useContext(MapContext);
	const [infoCage, setInfoCage] = useState(false);
	const [infoPredictions, setInfoPredictions] = useState(false);
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	useEffect(() => {
		if (!map) return;

		const selectInteraction = new Select({});

		map.addInteraction(selectInteraction);

		selectInteraction.getFeatures().on('add', (e) => {
			const feature = e.element;
			setInfoCage(feature);
			toggle();
		});

		return () => map.removeInteraction(selectInteraction);
	}, [map]);

	const { messages } = props.i18n || { messages: {} };


	useEffect(() => {
		const predictions = [];
		if (infoCage && !props.list.predictions.padding) {
			props.list.predictions.data.forEach(prediction => {
				if (prediction.cage === infoCage.get('uuid')) {
					predictions.push(prediction)
				}
			});
			setInfoPredictions(predictions)
		}
	}, [infoCage]);

	return <div>
		<Modal isOpen={modal} toggle={toggle} size="xl" style={{ maxWidth: '980px' }}>
			<ModalHeader toggle={toggle}>Κλωβός: {infoCage && infoCage.get('location')}</ModalHeader>
			<ModalBody>
				<Container>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<CardTitle tag="h5">Δεδομένα Αρχικοποίησης Κλωβού</CardTitle>
									<Table>
										<tbody>
											<tr>
												<td><b>Τύπος Κλωβού</b></td>
												<td>{infoCage && infoCage.get("cagetype")}</td>
												<td><b>Όγκος Κλωβού</b></td>
												<td>{infoCage && infoCage.get("cagevolume")} m³</td>
												<td><b>Βάθος Κλωβού</b></td>
												<td>{infoCage && infoCage.get("cageheight")} m</td>
												<td><b>Περίμετρος Κλωβού</b></td>
												<td>{infoCage && infoCage.get("cagelength")} m</td>
											</tr>
											<tr>
												<td><b>Eίδος Ψαριών</b></td>
												<td>{infoCage && messages[infoCage.get("fishspecies")]}</td>
												<td><b>Αριθμός Ψαριών</b></td>
												<td>{infoCage && infoCage.get("fishnumber")}</td>
												<td><b>Μέγεθος Ψαριών</b></td>
												<td>{infoCage && infoCage.get("fishsize")} gr</td>
												<td><b>Τελικό Εμπορεύσιμο Βάρος</b></td>
												<td>{infoCage && infoCage.get("finalmarketableweight")} gr</td>
											</tr>
											<tr>
												<td><b>Θερμοκρασία</b></td>
												<td>{infoCage && infoCage.get("temperature")} °C</td>
												<td><b>Οξυγόνο</b></td>
												<td>{infoCage && infoCage.get("oxygen")} %</td>
												<td><b>Τελευταία Επεξεργασία</b></td>
												<td>{infoCage && infoCage.get("modified").split(' ')[0]}</td>
											</tr>
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<CardTitle tag="h5">Πρόγνωση</CardTitle>
									<Table>
										<thead>
											<tr>
												<th>Ημέρα</th>
												<th>Βάρος (gr)</th>
												<th>Βάρος (SD)</th>
												<th>Τροφή (kg)</th>
												<th>Μέσο MO2 (g/kg/d)</th>
												<th>MO2 (SD)</th>
												<th>Βιομάζα (kg)</th>
												<th>Συνολικό MO2 (g/d)</th>
											</tr>
										</thead>
										<tbody>
											{
												infoPredictions && infoPredictions.map((pr, i) =>
													<tr key={`pr-key-${i}`}>
														<td>{pr.day.split(' ')[0]}</td>
														<td>{pr.weight}</td>
														<td>{pr.sdweight}</td>
														<td>{pr.feed}</td>
														<td>{pr.meanmo2}</td>
														<td>{pr.sdmo2}</td>
														<td>{pr.biomass}</td>
														<td>{pr.totalmo2}</td>
													</tr>)
											}
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</ModalBody>
		</Modal>
	</div>;
};


const mapStateToProps = (state) => ({
	loggedin: state.profile.loggedin,
	i18n: state.i18n,
	profile: state.profile,
	notifications: state.notifications.messages,
	pending: state.list.cagesMap.pending,
	status: state.list.cagesMap.status,
	errorMsg: state.list.cagesMap.errorMsg,
	modalOpen: state.ui.modal.modalOpen,
	list: state.list,
});

export default connect(mapStateToProps)(FeatureInfoControl);