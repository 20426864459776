import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Chart from "react-apexcharts";
import { Spinner } from 'reactstrap';


class WeatherChartLine extends Component {

  render() {
    return (
      <div>
        {
          this.props.series ?
            <Chart
              options={{
                title: {
                  text: this.props.showTitle ? this.props.title : '',
                  align: 'left',
                  style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: undefined,
                    color: '#263238'
                  }
                },
                stroke: {
                  // curve: 'smooth',
                  width: 2,
                },
                chart: {
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      selection: true,
                      zoom: true,
                      zoomin: true,
                      zoomout: true,
                      pan: true,
                      reset: true,
                      customIcons: []
                    },
                  },
                },
                xaxis: {
                  labels: {
                    rotate: -45,
                    rotateAlways: true
                  },
                  categories: this.props.categories,
                  title: {
                    text: this.props.xaxisTitle,
                    offsetX: 0,
                    offsetY: 120,
                    style: {
                      color: undefined,
                      fontSize: '12px',
                      fontFamily: undefined,
                      fontWeight: 100,
                    },
                  }
                },
                yaxis: {
                  forceNiceScale: true,
                  decimalsInFloat: 1,
                  title: {
                    text: `${this.props.yaxisTitle ? this.props.yaxisTitle : ''} ${this.props.units ? this.props.units : ''}`,
                    style: {
                      color: undefined,
                      fontSize: '12px',
                      fontFamily: undefined,
                      fontWeight: 100,
                    },
                  },
                },
                colors: ["#00A3E6", "#FF5C5C", "#f4af03"],
                dataLabels: {
                  enabled: false,
                }
              }}
              series={this.props.series}
              type="line"

              height={this.props.height || 250}
            />
            :
            <div style={{ textAlign: 'center' }}>
              <Spinner style={{ width: '4rem', height: '4rem' }} />
            </div>
        }
      </div>
    );
  }
}

WeatherChartLine.propTypes = {
  series: PropTypes.array.isRequired
};

export default WeatherChartLine;
