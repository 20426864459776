import { StaticRoutes, DynamicRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'termsOfUse',
		icon: 'fa fa-globe',
		url: StaticRoutes.TermsOfUse,
		role: roles.GUEST
	}
];

export default menu;