import React, { useState } from "react";
import { connect } from 'react-redux';
import Map from "../../components/Map";
import { Layers, LayerGroup, TileLayer, ImageLayer } from "../../components/Layers";
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from 'reactstrap';
import { bing, imageWMS } from "../../components/Source";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  BookmarkControl,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  InfoPercentilesControl,
  LegendControl,
  OpacityControl,
  BasemapControl
} from "../../components/Controls";
import "../../css/style.css";

import config from "../config.json";

const Percentiles = (props) => {
  const [center, setCenter] = useState(config.places[0].center);
  const [zoom, setZoom] = useState(config.places[0].zoom);
  const [activePercentilesLayer, setPercentilesLayer] = useState([config.model.percentiles[0]]);

  const handleSelect = (e) => {
    const value = e.target.value;
    setPercentilesLayer([value]);
  }

  return (

    <Card>
      <CardBody>
        <CardTitle tag="h5">{props.i18n.messages['change']}</CardTitle>
        <Row>
          <Col>
            <Label for="percentiles_layer_selector">{props.i18n.messages['select']}</Label>
            <Input id="percentiles_layer_selector" type="select" value={activePercentilesLayer[0]} onChange={handleSelect}>
              {
                config.model.percentiles.map(e => <option value={e} key={`percentiles_layer_option_${e}`}>{props.i18n.messages[e]}</option>)
              }
            </Input>
          </Col>
        </Row>
        <Row>
          <Col>
            <Map center={center} zoom={zoom} loggedin={props.loggedin}>
              <Layers key={`map2-key-${Math.random()}`}>
                <TileLayer title={props.i18n.messages['Road']} name={'Road'} baseLayer={true} visible={false} source={bing('Road', props.i18n.locale)} zIndex={0} logo={"/img/road.png"} />
                <TileLayer title={props.i18n.messages['Aerial']} name={'Aerial'} baseLayer={true} visible={false} source={bing('Aerial', props.i18n.locale)} zIndex={0} logo={"/img/aerial.png"} />
                <TileLayer title={props.i18n.messages['Hybrid']} name={'Hybrid'} baseLayer={true} visible={true} source={bing('AerialWithLabelsOnDemand', props.i18n.locale)} zIndex={0} logo={"/img/hybrid.png"} />
                {
                  activePercentilesLayer.map(e => <ImageLayer
                    cropCoordinates={config.crop}
                    key={`active-percintile-layer-${e}`}
                    name={props.i18n.messages[e]}
                    baseLayer={false}
                    visible={true}
                    extent={[2224961, 4112383, 3213139, 5161710]}
                    opacity={0.8}
                    source={imageWMS({
                      url: config.model.wms,
                      params: { 'LAYERS': e, 'TILED': true }
                    })} />)
                }
              </Layers>
              <Controls>
                <InitialZoomControl center={fromLonLat(config.center)} zoom={config.zoom} />
                <ZoomControl />
                <ScaleLineControl />
                <InfoPercentilesControl key={`map-info-key-${Math.random()}`} cropCoordinates={config.crop} activeLayer={activePercentilesLayer[0]} messages={props.i18n.messages} />
                <LegendControl wms={config.model.wms} activeLayer={activePercentilesLayer[0]} style={{ top: 'unset', bottom: '0.5em' }} />
                <BookmarkControl places={config.places} messages={props.i18n.messages} />
                <OpacityControl initOpacity={0.8} />
                <BasemapControl />
              </Controls>
            </Map>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Percentiles);