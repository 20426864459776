import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLImageLayer from "ol/layer/Image";
import Crop from "ol-ext/filter/Crop";
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';

const ImageLayer = ({ name, baseLayer, visible = true, extent, source, zIndex = 0, opacity = 1, className = null, cropCoordinates = null }) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let imageLayer = new OLImageLayer({
			className,
			name,
			baseLayer,
			visible,
			extent,
			source,
			zIndex,
			opacity
		});

		if (cropCoordinates) {
			const f = new Feature(new MultiPolygon(cropCoordinates));
			const crop = new Crop({
				feature: f,
				wrapX: true,
				inner: false
			})
			imageLayer.addFilter(crop);
		}

		map.addLayer(imageLayer);
		imageLayer.setZIndex(zIndex);

		return () => {
			if (map) {
				map.removeLayer(imageLayer);
			}
		};
	}, [map]);

	return null;
};

export default ImageLayer;
