import * as roles from 'core/model/roles';
import { StaticRoutes as CoreStaticRoutes } from 'core/model/routes';

const Login = '/login';
const Register = '/register';
const PassRecovery = '/password_recovery';

export const StaticRoutes = {
	Login, Register, PassRecovery
};

export const routes = [
	{
		url: Login,
		name: 'login',
		to: CoreStaticRoutes.AdminDashboard,
		role: roles.ADMIN,
	},
	{
		url: Login,
		name: 'login',
		to: CoreStaticRoutes.Profile,
		role: roles.AUTHORIZED,
	},
	{
		url: Login,
		name: 'login',
		component: 'Login',
		role: roles.GUEST,
	},
	{
		url: Register,
		name: 'registration',
		component: 'Register',
		role: roles.GUEST,
	},
	{
		url: PassRecovery,
		name: 'password recovery',
		component: 'PassRecovery',
		role: roles.GUEST,
	}
]

const combined = {StaticRoutes, routes};

export default combined;
