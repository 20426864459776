import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button
} from 'reactstrap';
import {login, logout} from 'core/ducks/profile';
import T from 'modules/i18n';
import LoginForm from '../forms/loginForm';
import LogoutForm from '../forms/logoutForm';
import {StaticRoutes as Pages} from '../../model/routes';


class Login extends Component {

    render() {

        return (
            <div className="app-container flex-row align-items-center">
                {this.props.profile.loggedin ?
                    // this.props.history.push('/')

                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8">
                                <CardGroup>
                                    <LogoutForm submit={this.props.logout} notifications={this.props.notifications}/>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>

                    :
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8">
                                <CardGroup>
                                    <LoginForm submit={this.props.login} notifications={this.props.notifications}/>
                                    <Card className="text-muted py-5 d-md-down-none" style={{width: 44 + '%'}}>
                                        <CardBody className="text-center">
                                            <div>
                                                <h2><T>sign up</T></h2>
                                                <p><T>sign_up_note</T></p>
                                                <Link to={Pages.Register}>
                                                    <Button color="primary" className="mt-3" active>
                                                        <T>register now</T>!
                                                    </Button>
                                                </Link>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                        <Row className="d-lg-none justify-content-center">
                            <Col md="8" className="my-0 py-0">
                                <Card className="text-muted p-4">
                                    <CardBody className="text-center">
                                        <div>
                                            <h2><T>sign up</T></h2>
                                            <p><T>sign_up_note</T></p>
                                            <Link to={Pages.Register}>
                                                <Button color="primary" className="mt-3" active>
                                                    <T>register now</T>!
                                                </Button>
                                            </Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    login,
    logout
}, dispatch);

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export default Login;
