import React, { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import { transform } from 'ol/proj';
import OLTileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import Papa from 'papaparse';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import ChartLine from "../RasterChartLine";
import MapContext from "../Map/MapContext";

const InfoControl = ({ index, year, model, period, activeLayer, i18n, cropCoordinates = null }) => {
  const { map } = useContext(MapContext);
  const [modal, setModal] = useState(false);
  const [infoResults, setInfoResults] = useState(null);
  const [infoCharts, setInfoCharts] = useState(false);
  const { messages } = i18n || { messages: {} };

  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {
      setInfoResults(null);
      setInfoCharts(false);
    }
  }

  useEffect(() => {
    if (!map) return;

    map.on('singleclick', showInfo);

    return () => map.un('singleclick', showInfo);

  }, [map, index, year, model, period, activeLayer]);


  const showInfo = (evt) => {
    setInfoResults(null);
    setInfoCharts(false);

    let inside = true;
    if (cropCoordinates) {
      const f = new Feature(new MultiPolygon(cropCoordinates));
      inside = (f.getGeometry().intersectsCoordinate(evt.coordinate));
    }

    if (!inside) return;
    const requests = [];
    const requestCharts = [];
    const viewResolution = /** @type {number} */ (map.getView().getResolution());
    const tileLayer = new OLTileLayer({
      name: activeLayer,
      source: new TileWMS({
        url: 'https://app.adapt2climate.gr/geoserver/gisdata/wms',
        params: { 'LAYERS': activeLayer, 'TILED': true },
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      })
    });

    let url = tileLayer.getSource().getFeatureInfoUrl(
      evt.coordinate,
      viewResolution,
      'EPSG:3857',
      { 'INFO_FORMAT': 'application/json' }
    );
    if (url) {
      // point info
      url += '&DIM_MODEL=' + model + '&TIME=' + year + '-01-01T00:00:00.000Z';
      requests.push(fetch(url).then(y => y.json()));

      // history info
      const urlChart = url.replace('&REQUEST=GetFeatureInfo', '&REQUEST=GetTimeSeries')
        .replace('&I=', '&X=')
        .replace('&J=', '&Y=')
        .replace('&INFO_FORMAT=application%2Fjson', '&INFO_FORMAT=text%2Fcsv');
      const requestChart = (urlChart.split('&TIME=')[0] + '&TIME=' + period[0] + '-01-01T00:00:00.000Z/' + period[1] + '-01-01T00:00:00.000Z');
      requestCharts.push(fetch(requestChart).then(y => y.text()));
    }

    Promise.all(requests).then(results => {
      if (results[0].features.length > 0) {
        setInfoResults({ index: results[0].features[0].properties.GRAY_INDEX, coordinates: transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326') });

      }
    });

    Promise.all(requestCharts).then(async rCharts => {
      const data = rCharts.map(csv => toJson(csv));
      const responses = await Promise.all(data)
      setInfoCharts(responses[0]);
      toggle();
    })

  }

  const toJson = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        worker: true,
        complete(results, file) {
          resolve(results.data)
        },
        error(err, file) {
          reject(err)
        }
      })
    })
  }

  const findAverageValue = (arr) => {
    let sum = 0;
    let count = 0;
    for (let obj of arr) {
        if (obj[1]) {
            sum += Number(obj[1]);
            count++;
        }
    }
    return (sum / count).toFixed(1);
}

  return <div>
    <Modal isOpen={modal} toggle={toggle} size={'lg'}>
      <ModalHeader toggle={toggle}>{messages[index]}</ModalHeader>
      <ModalBody>
        <Container>
          {
            infoResults && <div>
              <div style={{ textAlign: 'center' }}>
                {`${messages['year']}: ${year} - ${messages['value']}: ${parseFloat(infoResults.index).toFixed(1)}`}
                <br/>
                { infoCharts && `${messages['mean']} (${infoCharts.slice(3, infoCharts.length)[0][0].split('-')[0]} - ${infoCharts.slice(3, infoCharts.length)[infoCharts.length-4][0].split('-')[0]}): ${findAverageValue(infoCharts.slice(3, infoCharts.length))}`}
              </div>
              <ChartLine showTitle={false} title={messages['value']} series={infoCharts} />
              <div style={{ textAlign: 'right' }}>{`${messages['coordinates']}: ${infoResults.coordinates[0].toFixed(3)}, ${infoResults.coordinates[1].toFixed(3)}`}</div>
            </div>
          }
        </Container>
      </ModalBody>
    </Modal>
  </div>;
};


const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(InfoControl);