import TileWMS from 'ol/source/TileWMS';

function wms({ url, params }) {
	return new TileWMS({
		url,
    params
	});
}

export default wms;
