import React from "react";

import Climate from "../modals/climate";
import Percentiles from "../modals/percentiles";
import "../../css/style.css";


const ClimateData = () => {
  
  return (
    <div>
      <Climate />
      <Percentiles />
    </div>
  );
};

export default ClimateData;