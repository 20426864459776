import React, { useContext, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import { transform } from 'ol/proj';
import OLTileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import Papa from 'papaparse';
import Feature from 'ol/Feature';
import MultiPolygon from 'ol/geom/MultiPolygon';
import ChartLine from "../RasterChartLine";
import MapContext from "../Map/MapContext";

const InfoControl = ({ activeLayer, i18n, cropCoordinates = null }) => {
  const { map } = useContext(MapContext);
  const [modal, setModal] = useState(false);
  const [infoResults, setInfoResults] = useState(null);
  const { messages } = i18n || { messages: {} };

  const toggle = () => {
    setModal(!modal);
    if (!modal === false) {
      setInfoResults(null);
    }
  }

  useEffect(() => {
    if (!map) return;

    map.on('singleclick', showInfo);

    return () => map.un('singleclick', showInfo);

  }, [map, activeLayer]);


  const showInfo = (evt) => {
    setInfoResults(null);

    let inside = true;
    if (cropCoordinates) {
      const f = new Feature(new MultiPolygon(cropCoordinates));
      inside = (f.getGeometry().intersectsCoordinate(evt.coordinate));
    }

    if (!inside) return;

    const requests = [];
    const viewResolution = /** @type {number} */ (map.getView().getResolution());
    const tileLayer = new OLTileLayer({
      name: activeLayer,
      source: new TileWMS({
        url: 'https://app.adapt2climate.gr/geoserver/gisdata/wms',
        params: { 'LAYERS': activeLayer, 'TILED': true },
        serverType: 'geoserver',
        crossOrigin: 'anonymous',
      })
    });

    let url = tileLayer.getSource().getFeatureInfoUrl(
      evt.coordinate,
      viewResolution,
      'EPSG:3857',
      { 'INFO_FORMAT': 'application/json' }
    );
    if (url) {
      requests.push(fetch(url).then(y => y.json()));

    }

    Promise.all(requests).then(results => {
      if (results[0].features.length > 0) {
        setInfoResults({ index: results[0].features[0].properties.GRAY_INDEX, coordinates: transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326') });
        toggle();
      }
    });

  }


  return <div>
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>{messages[activeLayer]}</ModalHeader>
      <ModalBody>
        <Container>
          {
            infoResults && <div>
              <div style={{ textAlign: 'center' }}>
                {`${messages['value']}: ${parseFloat(infoResults.index).toFixed(1)}`}
              </div>
              <div style={{ textAlign: 'center' }}>{`${messages['coordinates']}: ${infoResults.coordinates[0].toFixed(3)}, ${infoResults.coordinates[1].toFixed(3)}`}</div>
            </div>
          }
        </Container>
      </ModalBody>
    </Modal>
  </div>;
};


const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(InfoControl);