import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'VulnerabilityIndex',
		icon: 'icon-map',
		url: StaticRoutes.VulnerabilityIndex,
		role: roles.GUEST,
	},
	{
		name: 'evaluation',
		icon: 'icon-map',
		url: StaticRoutes.Zones,
		role: roles.GUEST,
	},
	{
		name: 'climate',
		icon: 'icon-map',
		url: StaticRoutes.Climate,
		role: roles.GUEST,
	},	
	{
		name: 'stations',
		icon: 'icon-map',
		url: StaticRoutes.Stations,
		role: roles.GUEST,
	},
	{
		name: 'VulnerabilityIndex',
		icon: 'icon-map',
		url: StaticRoutes.VulnerabilityIndex,
		role: [roles.ADMIN, roles.AUTHORIZED]
	},
	{
		name: 'zones',
		icon: 'icon-map',
		url: StaticRoutes.AdminZones,
		role: [roles.ADMIN, roles.AUTHORIZED],
	},
	{
		name: 'climate',
		icon: 'icon-map',
		url: StaticRoutes.AdminClimate,
		role: [roles.ADMIN, roles.AUTHORIZED],
	},
	{
		name: 'stations',
		icon: 'icon-map',
		url: StaticRoutes.AdminStations,
		role: [roles.ADMIN, roles.AUTHORIZED],
	}
];

export default menu;
