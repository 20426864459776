import React, { useState } from "react";
import { connect } from 'react-redux';
import Map from "../../components/Map";
import { Layers, LayerGroup, TileLayer } from "../../components/Layers";
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { wms, bing } from "../../components/Source";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  InfoZonesControl,
  BookmarkControl,
  LegendControl,
  OpacityControl,
  BasemapControl
} from "../../components/Controls";
import "../../css/style.css";

import config from "../config.json";

const Zones = (props) => {
  const [center, setCenter] = useState(config.zones.center);
  const [zoom, setZoom] = useState(config.zones.zoom);
  const [activeLayers, setActivelayers] = useState(config.zones.layers);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">{props.i18n.messages['zones']}</CardTitle>
        <Row>
          <Col>
            <Map center={fromLonLat(center)} zoom={zoom} loggedin={props.loggedin}>
              <Layers key={`map-key-${Math.random()}`}>
                <TileLayer title={props.i18n.messages['Road']} name={'Road'} baseLayer={true} visible={false} source={bing('Road', props.i18n.locale)} zIndex={0} logo={"/img/road.png"} />
                <TileLayer title={props.i18n.messages['Aerial']} name={'Aerial'} baseLayer={true} visible={false} source={bing('Aerial', props.i18n.locale)} zIndex={0} logo={"/img/aerial.png"} />
                <TileLayer title={props.i18n.messages['Hybrid']} name={'Hybrid'} baseLayer={true} visible={true} source={bing('AerialWithLabelsOnDemand', props.i18n.locale)} zIndex={0} logo={"/img/hybrid.png"} />
                {
                  activeLayers.map(e => <TileLayer
                    key={`active-station-layer-${e}`}
                    name={props.i18n.messages[e]}
                    baseLayer={false}
                    visible={true}
                    extent={[2224961, 4112383, 3213139, 5161710]}
                    opacity={1}
                    source={wms({
                      url: config.zones.wms,
                      params: { 'LAYERS': e, 'TILED': true }
                    })} />)
                }
              </Layers>
              <Controls>
                <InitialZoomControl center={fromLonLat(center)} zoom={zoom} />
                <ZoomControl />
                <ScaleLineControl />
                <InfoZonesControl key={`map-info-key-${Math.random()}`} activeLayer={activeLayers[0]} layers={config.stations.layers} />
                <BookmarkControl places={config.zones.places} messages={props.i18n.messages} />
                <LegendControl key={`map-legend-key-${Math.random()}`} wms={config.model.wms} activeLayer={'akz_athens'} sld={props.i18n.locale === 'en' ? 'akz_athens_en' : ''} style={{ top: 'unset', bottom: '0.5em' }} />
                <OpacityControl />
                <BasemapControl />
              </Controls>
            </Map>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Zones);