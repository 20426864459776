import React, { useState } from "react";
import { connect } from 'react-redux';
import Map from "../../components/Map";
import { Layers, LayerGroup, TileLayer } from "../../components/Layers";
import { Card, CardBody, CardTitle, Row, Col, Label, Input } from 'reactstrap';
import { wms, bing } from "../../components/Source";
import { fromLonLat } from "ol/proj";
import {
  Controls,
  AttributionControl,
  InitialZoomControl,
  ZoomControl,
  ScaleLineControl,
  InfoStationControl,
  BookmarkControl,
  LegendControl,
  BasemapControl
} from "../../components/Controls";
import "../../css/style.css";

import config from "../config.json";

const Stations = (props) => {
  const [center, setCenter] = useState(config.places[0].center);
  const [zoom, setZoom] = useState(config.places[0].zoom);
  const [activeLayers, setActivelayers] = useState([config.stations.layers[0]]);

  const handleSelect = (e) => {
    const value = e.target.value;
    setActivelayers([value]);
  }

  return (

    <div>
      <Card>
        <CardBody>
          <CardTitle tag="h5">{props.i18n.messages['stations']}</CardTitle>
          <CardBody>
            {props.i18n.messages['stationsText']}
          </CardBody>
          <Row>
            <Col>
              <Label for="layer_selector">{props.i18n.messages['select']}</Label>
              <Input id="layer_selector" type="select" value={activeLayers[0]} onChange={handleSelect}>
                {
                  config.stations.layers.map(e => <option value={e} key={`layer_option_${e}`}>{props.i18n.messages[e]}</option>)
                }
              </Input>
            </Col>
          </Row>
          <Row>
            <Col>
              <Map center={center} zoom={zoom} loggedin={props.loggedin}>
                <Layers key={`map-key-${Math.random()}`}>
                  <TileLayer title={props.i18n.messages['Road']} name={'Road'} baseLayer={true} visible={false} source={bing('Road', props.i18n.locale)} zIndex={0} logo={"/img/road.png"} />
                  <TileLayer title={props.i18n.messages['Aerial']} name={'Aerial'} baseLayer={true} visible={true} source={bing('Aerial', props.i18n.locale)} zIndex={0} logo={"/img/aerial.png"} />
                  <TileLayer title={props.i18n.messages['Hybrid']} name={'Hybrid'} baseLayer={true} visible={false} source={bing('AerialWithLabelsOnDemand', props.i18n.locale)} zIndex={0} logo={"/img/hybrid.png"} />
                  {
                    activeLayers.map(e => <TileLayer
                      key={`active-station-layer-${e}`}
                      name={props.i18n.messages[e]}
                      baseLayer={false}
                      visible={true}
                      extent={[2224961, 4112383, 3213139, 5161710]}
                      opacity={1}
                      source={wms({
                        url: config.stations.wms,
                        params: { 'LAYERS': e, 'TILED': true }
                      })} />)
                  }
                </Layers>
                <Controls>
                  <InitialZoomControl center={fromLonLat(config.center)} zoom={config.zoom} />
                  <ZoomControl />
                  <ScaleLineControl />
                  <InfoStationControl key={`map-info-key-${Math.random()}`} activeLayer={activeLayers[0]} layers={config.stations.layers} />
                  <BookmarkControl places={config.places} messages={props.i18n.messages} />
                  <LegendControl wms={config.model.wms} activeLayer={activeLayers[0]} style={{ top: 'unset', bottom: '0.5em' }} />
                  <BasemapControl />
                </Controls>
              </Map>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedin: state.profile.loggedin,
  i18n: state.i18n,
  profile: state.profile,
  notifications: state.notifications.messages,
  modalOpen: state.ui.modal.modalOpen,
  list: state.list,
});

export default connect(mapStateToProps)(Stations);