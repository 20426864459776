import React, { useContext, useEffect, useState } from "react";
import MapContext from "../Map/MapContext";


const BookmarkControl = ({ places, messages }) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

  }, [map]);

  return <div className="ol-control ol-custom-zoomto-div">
    <div style={{padding: '5px', backgroundColor: '#4C6F98', borderRadius: '4px'}}>
      {
        places.map((e) => <div key={`key-zoom-to-place-${Math.random()}`}>
          <a href={`#${e.name}`} style={{ color: '#fff' }} onClick={() => {
            map.getView().setZoom(e.zoom)
            map.getView().setCenter(e.center)
          }} >{messages[e.name]}</a>
        </div>)
      }
    </div>
  </div>;
};

export default BookmarkControl;