import React, { useContext, useEffect, useState } from "react";
import Swipe from "./ol-ext/control/Swipe";
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import MapContext from "../Map/MapContext";


const SwipeControl = ({ wms, layer, opacity, titleLeft = '', titleRight = '' }) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		const imageLeftlayer = new TileLayer({
			opacity: opacity,
			source: new TileWMS({
				url: wms,
				params: { 'LAYERS': layer }
			})
		});

		map.addLayer(imageLeftlayer);

		const swipeControl = new Swipe({
			titleLeft: titleLeft,
			titleRight: titleRight
		});

		swipeControl.addLayer(imageLeftlayer);

		map.controls.push(swipeControl);

		return () => {
			map.controls.remove(swipeControl);
			map.removeLayer(imageLeftlayer);
		}
	}, [map]);

	return null;
};

export default SwipeControl;